import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from "react-bootstrap/Button";

function PackageStatusPage() {
    function handleSubmit() {
        if (window.confirm('Confirm successful delivery of package?')) {
            console.log('Mark as delivered');
        } else {
            console.log('Operation canceled');
        }
    }

    return (
        <Container>
            <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                <Col xs={6} md={6}>
                    <Card>
                        <Card.Img variant="top" src="https://cdn9.dissolve.com/p/D2058_13_008/D2058_13_008_0004_600.jpg"/>
                        <Card.Body  className="text-center">
                            <Card.Title>Package Status</Card.Title>
                            <Card.Text>
                                A summary of your package status is below
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item><b>1. Order Placed</b></ListGroup.Item>
                            <ListGroup.Item>2. Order Dispatched</ListGroup.Item>
                            <ListGroup.Item>3. Out for Delivery</ListGroup.Item>
                            <ListGroup.Item>4. Delivered</ListGroup.Item>
                        </ListGroup>
                        <Card.Body className="text-center">
                            <Button variant="primary" onClick={handleSubmit} >
                                Confirm Delivery
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default PackageStatusPage;