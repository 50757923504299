import {Navbar} from "react-bootstrap";
import React from "react";

function NavbarComponent() {
    return (
        <Navbar className="bg-body-tertiary" fixed="bottom" >
            <div className="w-100 text-center">
                © 2023 Dolphin Systems
            </div>
        </Navbar>
    )
}

export default NavbarComponent;
