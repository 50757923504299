import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useState} from "react";

function PackageStatusPage() {

    const  [trackingNumber, setTrackingNumber] =  useState('');
    const  handleChange = (event) => setTrackingNumber(event.target.value);

    function handleSubmit(e) {
        e.preventDefault();
        window.location.href = "/track/"+trackingNumber;
    }


    return (
        <Container>
            <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                <Col s={12} md={9} lg={6}>
                    <Card>
                        <Card.Img variant="top" src="https://eptica.com/sites/default/files/styles/illustration_principale_article_blog/public/csi_blog_-_eptica.jpg?itok=L0_4v3cv" />
                        <Card.Body  className="text-center">
                            <Card.Title>Package Tracking</Card.Title>
                            <Card.Text>
                                <Form onSubmit={e=>handleSubmit(e)}>
                                    <br/>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control value={trackingNumber} onChange={handleChange} placeholder="Enter tracking number" />
                                    </Form.Group>
                                    <br/>
                                    <Button variant="primary" type="submit" >
                                        Submit
                                    </Button>
                                </Form>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default PackageStatusPage;