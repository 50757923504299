import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import PackageTrackingNumberPage from "./pages/PackageTrackingNumberPage";
import ErrorPage from "./pages/ErrorPage";
import NavbarComponent from "./components/NavbarComponent";
import FooterComponent from "./components/FooterComponent";
import PackageStatusPage from "./pages/PackageStatusPage";


const router = createBrowserRouter([
    {
        path: "/",
        element: <PackageTrackingNumberPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "track/:trackingNumber",
        element: <PackageStatusPage />,
    },
]);

function App() {
  return (
      <>
          <NavbarComponent />
          <RouterProvider router={router} />
          <FooterComponent />
      </>
  );
}

export default App;
