import {Navbar} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import React from "react";

function NavbarComponent() {
    return (
        <Navbar className="bg-body-tertiary" fixed="top" >
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src="https://image.similarpng.com/very-thumbnail/2021/05/Letter-D-logo-design-template-with-geometric-shape-style-on-transparent-background-PNG.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Dolphin
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}

export default NavbarComponent;
